<template>
  <div class="faq" :class="{ details: show }">
    <div ref="tcktinfo" class="info">
      <div class="item big" @click="details = !details">{{ faq.title }}</div>
      <div class="item" @click="details = !details">
        {{ faq.created_by?.name }}
      </div>
      <div class="item" @click="details = !details">
        {{ faq.updated_by?.name }}
      </div>
      <div class="item" @click="details = !details">
        <img src="/assets/images/down_arrow_blue.svg" />
      </div>
      <div class="options" v-if="canEdit" @click="edit = !edit">
        <img src="/assets/images/black_dots.svg" alt="" />
        <options-modal
          v-if="edit"
          :options="['view', 'edit']"
          @view="details = !details"
          @edit="editFaq = true"
          @delete="del()"
          v-click-outside="closeOptions"
        />
      </div>
    </div>
    <div
      class="details"
      ref="details"
      :class="{ open: details, close: !details }"
      v-if="show"
    >
      <span class="title">Answer:</span>
      <span v-html="faq.description.replace(/\\/g, '')"></span>
    </div>
    <edit-modal
        :size="'big'"
        :title="`Edit FAQ`"
        :items="toEdit"
        :response="response"
        v-if="editFaq"
        @close=";(editFaq = false), (response = [])"
        @submitData="editFaqs"
    />
  </div>
</template>

<script>
import axiosInstance from "@/services/AxiosTokenInstance";
import optionsModal from "@/components/Ui/General/Options.vue";
import EditModal from "@/components/Ui/CRUD/Edit.vue";
export default {
  props: {
    faq: {
      required: false,
      type: Object,
    },
    allChecked: {
      required: true,
      type: Boolean,
    },
    canEdit: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      imgURL: process.env.VUE_APP_DO_SPACES,
      edit: false,
      faqSrch: "",
      details: false,
      open: false,
      show: false,
      editFaq: false,
      response: []
    };
  },

  components: {
    optionsModal,
    EditModal,
  },

  watch: {
    details(val) {
      if (val) this.show = true;
      else {
        setTimeout(() => {
          this.show = false;
        }, 500);
      }
    },
  },

  computed: {
    toEdit() {
      return [
        {
          type: "text",
          label: "Title",
          value: this.faq.title,
        },
        {
          type: "texteditor",
          label: "Answer",
          value: this.faq.description,
        },
      ];
    },
  },

  methods: {
    closeOptions() {
      this.edit = false;
    },

    async del() {
      const response = await axiosInstance.delete(`faq/${this.faq.id}`);

      if (response) {
        this.emitter.emit("alert", response.data.message);
        this.$emit("updateTicket");
      }
    },

    async editFaqs(data) {
      const org = JSON.parse(localStorage.getItem("organization"));

      const formData = {
        organization_id: org.id,
        faq_id: this.faq.id,
        title: data.Title || this.faq.title,
        description: data.texteditor || this.faq.description,
        category_id: this.$route.params.catid,
      };

      const response = await axiosInstance.post(`update-faq`, formData);
      if (response) {
        this.emitter.emit("alert", response.data.message);
        this.$emit("updateTicket");
        this.response = [true, true];
      } else {
        this.response = [true, false];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
input[type="checkbox"] {
  opacity: 0.5;
}
.overlay {
  width: 100%;
  height: 80px;
  z-index: 2;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: center;
  border-bottom: solid 1px #f2f5f8;
  .bar {
    width: 15%;
    height: 30px;
    background: linear-gradient(270deg, #3b3b3b, #5b5d5d);
    background-size: 400% 400%;
    animation: load 1s ease infinite;
    border-radius: 30px;
    &.large {
      width: 20%;
      background: none;
      animation: unset;
      border-radius: none;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      .round {
        width: 30px;
        height: 30px;
        background: linear-gradient(270deg, #3b3b3b, #5b5d5d);
        background-size: 400% 400%;
        animation: load 0.5s ease infinite;
        border-radius: 50%;
      }
      .txt {
        display: flex;
        flex-direction: column;
        width: 85%;
        gap: 2px;

        div {
          background: linear-gradient(270deg, #3b3b3b, #5b5d5d);
          background-size: 400% 400%;
          animation: load 0.5s ease infinite;
          border-radius: 30px;
        }
        .top {
          height: 17px;
        }
        .bottom {
          height: 11px;
        }
      }
    }
  }
}
.faq {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 25px;
  background: white;
  border-bottom: solid 1px #f2f5f8;
  cursor: pointer;

  &.details {
    .info {
      .item {
        &:nth-last-child(2) {
          img {
            transform: rotate(180deg);
          }
        }
      }
    }
    .details {
      max-height: 1000px;
      visibility: visible;
      opacity: 1;
      background: #f2f5f8;
      padding: 10px 25px 25px;
      display: flex;
      flex-direction: column;
      position: relative;
      margin: 0;
      overflow: auto;
      font-size: 1rem;
      font-weight: normal;

      &.open {
        animation: open 0.5s ease-in-out forwards;
      }

      &.close {
        animation: close 0.5s ease-in-out forwards;
      }

      .title {
        font-weight: 500;
        padding: 10px 0;
      }
      .by {
        position: absolute;
        bottom: 5px;
        right: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        font-size: 0.7rem;
        font-style: italic;
        @media only screen and (max-width: 1200px) {
            opacity: 0;
        }
      }
    }
  }
  .info {
    width: 98%;
    height: 80px;
    display: flex;
    .item {
      width: 20%;
      height: 100%;
      display: flex;
      align-items: center;
      font-weight: 600;
      user-select: none;
      &.big {
        width: 50%;
      }
      &:nth-last-child(2) {
        width: 5%;
        img {
          transition: 0.2s;
          width: 25%;
          object-fit: cover;
        }
      }
      &.description {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .tkt-info {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 10px;
        .img {
          width: 30px;
          height: 30px;
          position: relative;
          img {
            height: 100%;
            object-fit: cover;
          }

          span {
            background: var(--primary-color);
            color: white;
            padding: 1px 5px;
            border-radius: 15px;
            position: absolute;
            bottom: -5px;
            right: 0;
            font-size: 8px;
          }
        }
        .no-img {
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          color: white;
          background: var(--primary-color);
        }
        .desc {
          display: flex;
          align-items: center;
          gap: 10px;

          .tck-desc {
            .name {
              &:first-letter {
                text-transform: uppercase;
              }
            }
            .time {
              color: #cad6e2;
              font-weight: 400;
              font-size: 0.7rem;
            }
          }
        }
      }
    }
  }
  .options {
    width: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    user-select: none;
    .dropdown-options {
      top: 60%;
    }
  }
}

@keyframes open {
  0% {
    max-height: 0px;
    opacity: 0;
    margin: 0;
  }
  100% {
    max-height: 1000px;
    opacity: 1;
    margin: 0 0 25px;
  }
}
@keyframes close {
  0% {
    max-height: 1000px;
    opacity: 1;
    margin: 0 0 25px;
  }
  100% {
    max-height: 0px;
    opacity: 0;
    margin: 0;
  }
}
</style>